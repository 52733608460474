<!-- 提现 -->
<template>
  <div class="withdraw-page">
    <div class="content">
      <div class="page-title">
        <div class="word f28 font-m color-666">{{ $i18n.t("wi_chain_type") }}</div>
        <div class="value">ETHereum Main NetWork</div>
      </div>
      <div class="page-title">
        <div class="word f28 font-m color-666">{{ $i18n.t("wi_account") }}</div>
      </div>
      <div class="select-line">
        <van-dropdown-menu active-color="#1989fa">
          <van-dropdown-item v-model="value1" :options="option1" />
        </van-dropdown-menu>
        <div class="less-num">
          <div class="word color-999">{{ $i18n.t("wi_quantity") }}</div>
          <div class="num f28 color-333 font-b font-bold">{{ userInfo.withdrawableUSD.toFixed(6) }}</div>
        </div>
      </div>
      <div class="form-block">
        <div class="form-item">
          <div class="label f28 font-m color-666">{{ $i18n.t("wi_wallet_address") }}</div>
          <div class="input-box">
            <div class="address f28 color-333 font-m">
              <!-- 0x14ba88D6eF683CCD776dDb71519d297133EA2FFD -->
              <input type="type" class="color-333" v-model="address" disabled />
              <!-- <div class="unit f28 color-333 font-m">USDC</div> -->
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label f28 font-m color-666">{{ $i18n.t("wi_withdrawal_quantity") }}</div>
          <!-- error -->
          <div class="input-box">
            <input type="tel" class="color-333" v-model="amount" :placeholder="`≥${systemConfig.minWithdrawAmount}USDC`" />
            <div class="unit f28 color-333 font-m">USDC</div>
            <!-- <div class="unit-box f28">
              <div class="unit">USDC</div>
            </div> -->
          </div>
          <!-- <div class="error-tip f24">不可大于提现</div> -->
          <div class="input-tip-item f24 color-666">
            {{ $i18n.t("wi_withdrawal_expenses") }} <span class="font-b font-bold">{{ systemConfig.withdrawFee }}% USDC</span>
          </div>
          <div class="input-tip-item f24 color-666">
            {{ $i18n.t("wi_actual_receipt") }} <span class="font-b font-bold">{{ num3 }} USDC</span>
          </div>
        </div>
      </div>
      <!-- <div class="account-count">
        <div class="count-line">
          <div class="label f28 color-999">可用余额</div>
          <div class="value f28 color-333 font-m">0.000000 USDC</div>
        </div>
        <div class="count-line">
          <div class="label f28 color-999">最小提现</div>
          <div class="value f28 color-333 font-m">0.000000 USDC</div>
        </div>
        <div class="count-line">
          <div class="label f28 color-999">手续费</div>
          <div class="value f28 color-333 font-m">0.000000 USDC</div>
        </div>
      </div> -->
      <div class="btn-line">
        <!-- <div class="item border-btn">
          <span class="theme-word-color">取消</span>
        </div> -->
        <div class="item confirm-btn active" @click="showPoup">{{ $i18n.t("wi_confirm") }}</div>
      </div>
    </div>
    <van-popup class="withdraw-popup" round v-model="showWithdrawBomb">
      <div class="withdraw-box">
        <div class="close" @click="showWithdrawBomb = !showWithdrawBomb">
          <img src="../assets/images/icon-close.svg" alt="" />
        </div>
        <div class="title f28">{{ $i18n.t("wi_pop1") }}</div>
        <div class="price">
          <img src="../assets/images/usdc.svg" alt="" />
          <div class="num font-b f40 font-bold">{{ amount }}</div>
        </div>
        <div class="tip f24">
          {{ $i18n.t("wi_tips") }}<br />
          {{ $i18n.t("wi_tip_content", {amount, payAmount}) }}
        </div>
        <button class="btn send-btn" @click="comfirmWith" :disabled="isDisabled">
          {{ $i18n.t("wi_confirm_withdrawal") }}
        </button>
      </div>
    </van-popup>
  </div>
</template>

<script>
function getFullNum(num){
    //处理非数字
    if(isNaN(num)){return num};
    //处理不需要转换的数字
    var str = ''+num;
    if(!/e/i.test(str)){return num;};
    return (num).toFixed(18).replace(/\.?0+$/, "");
}
export default {
  data() {
    return {
      isDisabled: false,
      address: null,
      amount: null,
      userInfo: {
        withdrawableUSD: 0,
        user: {
          address: "",
        },
      },
      showWithdrawBomb: false,
      value1: 0,
      option1: [
        {text: "USDC-ERC20", value: 0},
        // {text: "USDC-ERC20", value: 1},
        // {text: "USDC-ERC20", value: 2},
      ],
      // systemConfig: {
      //   minWithdrawAmount: 0, //最小提现数量 (USDT)
      //   withdrawFee: 1, //提现手续费
      //   withdrawTime: "00:00-23:59", // 处理提现时间
      //   withdrawTimeUtc: "00:00-23:59", //处理提现时间(UTC)
      // }
    };
  },
  computed: {
    num3() {
      if (this.amount == null || this.amount == "") {
        // this.money = 0
        return 0;
      } else {
        return this.substr6(parseFloat(this.amount) * parseFloat(`${1 - ((+this.systemConfig.withdrawFee)/100)}`));
      }
    },
    payAmount() {
      if (!this.amount) return 0;
      const tmp = this.amount - +this.num3;
      // eslint-disable-next-line no-unused-vars
      const [_lf, rt] = `${tmp}`.split(".");
      return rt && rt.length > 6 ? this.substr6(tmp) : tmp;
    },
    systemConfig() {
      return this.$store.state.user.sysConfig
    }
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    substr6(val) {
      val = typeof val === 'number' ? `${getFullNum(val)}` : val
      const [f, n] = val.split('.')
      return val == '0' ? 0 : `${f}${n ? `.${n.substr(0, 6)}` : ''}`
    },
    // 是否在提现时间段
    isQu(val) {
      const [f, s] = val.split('-')
      const cur = this.$moment().format('YYYY-MM-DD')
      return this.$moment().isBetween(`${cur} ${f}`, `${cur} ${s}`, null, '()')
    },
    showPoup() {
      if (!this.isQu(this.systemConfig.withdrawTimeUtc)) {
        this.$toast(this.$i18n.t("wi_withdrawable_time", {withdrawTime: this.systemConfig.withdrawTimeUtc}));
        return 
      }
      if (this.address == null || this.address == "") {
        this.$toast(this.$i18n.t("wi_receipt_address"));
        return;
      }
      if (this.amount == null || this.amount == "" || this.amount == "0") {
        this.$toast(this.$i18n.t("wi_please_quantity"));
        return;
      }
      if (parseFloat(this.amount) < this.systemConfig.minWithdrawAmount) {
        this.$toast(this.$i18n.t("wi_quantity50", {Amount: this.systemConfig.minWithdrawAmount}));
        return;
      }
      if (parseFloat(this.amount) > parseFloat(`${this.userInfo.withdrawableUSD}`)) {
        this.$toast(this.$i18n.t("wi_withdrawable_quantity"));
        return;
      }
      this.showWithdrawBomb = !this.showWithdrawBomb;
    },
    async comfirmWith() {
      this.isDisabled = true;
      try {
        const res = await this.$http.postUserApplyWithdraw({
          address: this.userInfo.user.address,
          amount: parseFloat(this.amount),
        });
        if (res.code === 0) {
          this.$toast(this.$i18n.t(res.msg));
          setTimeout(() => {
            this.$router.push("/record?status=3");
          }, 500)
        }
        this.isDisabled = false;
        this.showWithdrawBomb = false;
        this.getUserInfo();
      } catch (error) {
        this.isDisabled = false;
      }
    },
    getUserInfo() {
      let that = this;
      this.$http.getUserInfo().then((res) => {
        // console.log(res);
        if (res.code === 0) {
          that.userInfo = res.data;
          this.address = that.userInfo.user.address.slice(0, 6) + "******" + that.userInfo.user.address.slice(-6);
        }
      });
    },
  },
};
</script>

<style lang="less">
.withdraw-page {
  padding: 56px 0;
  .content {
    padding: 0.48rem 0.48rem;
    .page-title {
      margin-bottom: 0.48rem;
      .word {
        text-align: left;
        position: relative;
      }
      .value {
        text-align: left;
        line-height: 0.4rem;
        padding: 0.24rem 0.32rem;
        border: 1px solid #eee;
        border-radius: 0.24rem;
        margin-top: 0.2rem;
      }
    }
    .select-line {
      margin-bottom: 0.48rem;
      padding: 0.1rem 0;
      border-radius: 12px;
      border: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      .van-dropdown-menu {
        .van-dropdown-menu__bar {
          box-shadow: none;
          height: 48px;
          border-radius: 20px;
          .van-dropdown-menu__item {
            width: 100%;
            .van-dropdown-menu__title {
              display: block;
              display: flex;
              justify-content: space-between;
              .van-ellipsis {
                font-family: myMidFont;
                font-size: 0.28rem;
                color: #666;
                background: url("../assets/images/usdc.svg") no-repeat left center;
                background-size: 24px 24px;
                padding-left: 30px;
              }
            }
          }
        }
      }
      .less-num {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: right;
        padding-right: 0.2rem;
        padding-top: 0.1rem;
        .word {
          line-height: 0.3rem;
        }
        .num {
          line-height: 0.4rem;
        }
      }
    }
    .form-block {
      .form-item {
        text-align: left;
        margin-bottom: 0.48rem;
        .label {
          margin-bottom: 0.16rem;
          line-height: 0.4rem;
        }
        .label.require::before {
          content: "*";
          display: inline-block;
          color: #fd4c4c;
          padding-right: 2px;
        }
        .input-box {
          display: flex;
          border: 1px solid #eee;
          padding: 0.24rem 0.2rem;
          border-radius: 0.2rem;
          overflow: hidden;
          .address {
            flex: 1;
            line-height: 0.4rem;
            white-space: wrap;
            word-break: break-all;
          }
          input {
            width: 100%;
            height: 0.44rem;
            font-size: 0.28rem;
          }
          // .edit {
          //   width: 0.4rem;
          //   display: flex;
          //   flex-direction: column;
          //   justify-content: center;
          //   margin-left: 0.2rem;
          //   img {
          //     width: 0.4rem;
          //     height: 0.4rem;
          //     margin: 0 auto;
          //   }
          // }
          // .unit-box {
          //   display: flex;
          //   line-height: 0.4rem;
          //   margin-top: 0.2rem;
          //   .hr {
          //     padding: 0 0.1rem;
          //   }
          // }
          .unit {
            line-height: 0.44rem;
          }
        }
        .input-box.error {
          border: 1px solid #fd4c4c;
        }
        .error-tip {
          color: #fd4c4c;
          line-height: 0.4rem;
          margin-top: 0.1rem;
        }
        .input-tip-item {
          line-height: 0.4rem;
          margin-top: 0.1rem;
          span {
            padding-left: 0.1rem;
          }
        }
      }
    }
    .account-count {
      background: #f7f7f7;
      border-radius: 0.32rem;
      padding: 0.16rem 0.48rem;
      .count-line {
        display: flex;
        justify-content: space-between;
        line-height: 0.4rem;
        padding: 0.08rem 0;
      }
    }
    .btn-line {
      display: flex;
      margin-top: 0.8rem;
      .item {
        flex: 1;
        height: 0.88rem;
        line-height: 0.88rem;
        border-radius: 0.8rem;
        font-size: 0.32rem;
      }
      .item:first-child {
        // margin-right: 0.5rem;
        line-height: 0.84rem;
      }
      .confirm-btn {
        background: #e2e2e2;
        color: #fff;
      }
      .confirm-btn.active {
        background-image: linear-gradient(90deg, #5cbffe, #a0f5d0, #ffd7c8);
      }
    }
  }
  .withdraw-popup {
    width: 86%;
    padding: 0.48rem 0.48rem;
    .close {
      position: absolute;
      right: 0.2rem;
      top: 0.2rem;
    }
    .title {
      line-height: 0.4rem;
      color: #666;
    }
    .price {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 0.6rem;
      line-height: 0.6rem;
      margin: 0.08rem 0 0.4rem 0;
      img {
        width: 0.4rem;
        height: 0.4rem;
        margin-top: 0.1rem;
        margin-right: 0.1rem;
      }
    }
    .tip {
      color: #999;
      line-height: 0.36rem;
      text-align: justify;
      margin-bottom: 0.48rem;
    }
    .send-btn {
      width: 100%;
      height: 0.8rem;
      line-height: 0.8rem;
      font-size: 0.3rem;
    }
  }
}
</style>
